<template>
  <div id="comp">
    <div id="pano" ></div>

    <!-- Tooltip -->
    <span id="tooltip-span">{{this.tooltip}}</span>

    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style="z-index: 999999999999999 !important"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body" id="modal_body">
              <div v-if="this.hotspot" class="p-0">
                <div v-if="this.question">
                  <div class="modal-title">
                    <h4>{{ this.currentHotspot.hotspot_title + ' : Action Question' }}</h4>
                  </div>
                  <div class="p-4">
                    <h6>{{this.currentHotspot.question}}</h6>
                    <ul>
                      <li v-for="answer in this.currentHotspot.answers" :key="answer.id" style="list-style: none">
                        <input type="checkbox" class="form-check-input cursor-pointer" :value="answer" v-model="this.selected_answer" :name="'flexRadioDefault'+answer.id" :id="'flexRadioDefault1'+answer.id">
                        <label for="flexRadioDefault1" style="margin-left: 10px">{{answer.answer}}</label>
                      </li>
                    </ul>
                    <div class="alert alert-danger" id="wrong_answer" role="alert" v-if="this.trial > 1">
                      Wrong answer please try again!
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button class="btn btn-primary btn-rounded submit" 
                              @click="questionFun()" 
                      >
                        submit
                      </button>
                  </div>
                </div>
                <div v-if="this.settings">
                  <div class="modal-title">
                    <h4>{{ this.currentHotspot.hotspot_title + ' : Settings' }}</h4>
                  </div>
                  <div class="p-4">
                    {{this.currentHotspot.settings}}
                  </div>
                  <div class="modal-footer"> 
                    <button class="btn btn-primary btn-rounded submit" 
                              @click="settingsFun()" 
                      >
                        Apply
                      </button>
                  </div>
                </div>
                <div v-if="this.feedback">
                  <div class="modal-title">
                    <h4>{{ this.currentHotspot.hotspot_title + ' : Feadback'}}</h4>
                  </div>
                  <div class="p-4">
                    {{this.currentHotspot.feedback}}
                  </div>
                  <div class="modal-footer"> 
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                      @click="feedbackFun()"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
              <div v-else></div>
          </div>
          
        </div>
      </div>
    </div>

    <!-- success -->
    <div
      class="modal fade"
      id="successAlert"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-1" id="modal_body">
            <div class="alert round alert-success mb-0 text-center" role="alert">
              <h4>Well Done, Please click next to continue </h4>
              <button
                type="button"
                class="btn btn-secondary mt-3 mb-1"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- False -->
    <div
      class="modal fade"
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-1" id="modal_body">
            <div class="alert alert-danger mb-0 text-center" role="alert">
              <h3>Case Failed!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from "../store/store";
export default {
  name: "ICU Room",
  data() {

    return {
      hotspots: [],
      hotspot: null,
      opennedHotspot: {},
      nextHotspot: null,
      currentHotspot: null,
      allOpennedHotspots: [],
      index: 0,
      modal: false,
      question: false,
      settings: false,
      feedback: false,
      selected_answer: [],
      final:[],
      trial: 1,
      result: true,
      correctAnswersCounter: 0,
      temp:[],
      pushed: false,
      hotspotsLength: null,
      hotspotTooltip: null,
      tooltip: null,
    };
  },
  computed:{
    opennedHotspotListener(){
      if(this.allOpennedHotspots.length == this.hotspotsLength)
      {
        $('#successAlert').modal('show')
        this.$store.dispatch('nextNavigationStatus', true)
      }
      return this.allOpennedHotspots
    }
  },
  watch:{
    opennedHotspotListener(val , oldVal){
      // if(val.length == this.hotspotsLength){
      //   console.log('success');
      //   this.$store.dispatch('nextNavigationStatus', true)
      // }
    }
  },
  methods: {
    // get all data from api
    getHotspots(){
      this.hotspots = this.$store.getters["hotspots"];
      this.hotspotsLength = this.hotspots.body.length
    },

    // function of actions (questions) in each hotspot
    questionFun(){
      //check if student answer a wrong question
      this.selected_answer.forEach( (value, index) => {
        if(value.is_correct != 1){
          this.result = false;
        }
      })

      //count the number of the correct answers from the backend 
      this.currentHotspot.answers.forEach((value, index) => {
        if(value.is_correct == 1){
          this.correctAnswersCounter += 1
        }
      })

      //append student answers id to opennedHotspot, to send it later to the backend
      if(this.selected_answer.length > 0){
          let answers_id = [];
          this.selected_answer.forEach((val)=>{
            answers_id.push(val.id)
          })
          Object.assign( this.opennedHotspot , {'answers': answers_id})
      } 

      if(this.result == true && this.selected_answer.length == this.correctAnswersCounter){
        

        //push opnned hotspot depend on the flag (pushed)
        if(!this.pushed){
          this.allOpennedHotspots.push(this.opennedHotspot)
          this.pushed = true
        }

        this.question = false;

        //check if there settings in the hotspot after question or not
        if(this.currentHotspot.settings != null){
          this.settings = true;
          this.selected_answer = [];
        }
        //check if there feadback in the hotspot after question or not
        else if(this.currentHotspot.feedback != null){
          this.feedback = true;
          this.selected_answer = [];
        }
        else{
          this.correctAnswersCounter = 0;
          this.result = true
          this.selected_answer = []
          $('#staticBackdrop').modal('hide');
        }
        
        this.trial = 1;
        this.correctAnswersCounter = 0;
        this.result = true
        
      }
      else if(this.trial < 3){
        $("#wrong_answer").show();
        this.trial += 1;
        this.correctAnswersCounter = 0;
        this.selected_answer = []
        this.result = true
        window.setTimeout(function () { 
          $("#wrong_answer").hide(); 
        }, 2000);
      }
      else {
        $('#staticBackdrop').modal('hide');
        this.faild()
      }
    },

    // function of settingsin each hotspot
    settingsFun(){
      //push opnned hotspot depend on the flag (pushed)
      if(!this.pushed){
        this.allOpennedHotspots.push(this.opennedHotspot)
        this.pushed = true
      }
      this.question = false;
      this.settings = false;
      this.selected_answer = [];
      if(this.currentHotspot.feedback !=null){
        this.feedback = true;
      }
      else{
        this.feedback = false;
        $('#staticBackdrop').modal('hide')
      }
    },

    // function of feedback in each hotspot
    feedbackFun(){
      //push opnned hotspot depend on the flag (pushed)
        if(!this.pushed){
          this.allOpennedHotspots.push(this.opennedHotspot)
          this.pushed = true
        }
    },

    // function of faild scenario
    faild(){

      //push opnned hotspot depend on the flag (pushed)
        if(!this.pushed){
          //send the opened hotspot to the backend
          this.allOpennedHotspots.push(this.opennedHotspot)
          this.pushed = true
        }

        this.$store.dispatch( 'trialTime')

        //show faild message and reset all data
        $('#staticBackdrop').modal('hide')
        $('#staticBackdrop2').modal('show')
        let _this = this
        setTimeout( function(){ 
          $('#staticBackdrop').modal('hide')
          $('#staticBackdrop2').modal('hide')
          _this.$store.dispatch('prevNavigationStatus', true)
          _this.$store.dispatch('nextNavigationStatus', true)
          _this.hotspot = null
          _this.opennedHotspot = {}
          _this.allOpennedHotspot = []
          _this.nextHotspot = null
          _this.index = 0
          _this.$store.state.step = 11
          // _this.$store.state.currentState = 0
          _this.$store.state.stateStep = 0
          _this.$router.push({ name: `step11`, query: {'exp': _this.$store.state.case_key} });
        }  , 3000 );
    },

    // function that open popup for each hotspot
    loadpopup(hotspotLabel) {
      console.log(this.currentHotspot);
      console.log(hotspotLabel);
      //reset variables
      this.pushed = false
      this.opennedHotspot = {}
      this.currentHotspot = null

      //set the next hotspot that returned from api, by default hotspots[0]
      this.nextHotspot = this.hotspots.body[this.index]

      if (this.hotspots.body.some(el => el.label === hotspotLabel)) {
        //filter all hotspots to get the clicked one by the hotspot label 
        this.hotspot = this.hotspots.body.filter(function (el) {
          return el.label == hotspotLabel;
        });
      }
      else{
        this.pushed = true
        // this.faild()
        return alert('No need to this hotspot in this case');
      }

      this.currentHotspot = this.hotspot[0] // set current hotspot 

      //handle the object that will be send to the backend
      Object.assign( this.opennedHotspot , {'hotspot_id': this.currentHotspot.hotspot_id , 'question_id': this.currentHotspot.id , 'opened_in_order': this.index+1 , 'answers': this.selected_answer})
      
      //check if the clicked hotspot is openned in the wright order
      if((this.nextHotspot.sort == this.currentHotspot.sort || this.$store.getters["hotspotsOrder"] == 0) ){

        if(this.didNotOpennedBefore()){
          this.index += 1 //increment the index to get the next hotspot

          // reinitialize the popups status
          this.question = false
          this.settings = false
          this.feedback = false

          //decied which popup will open first
          if(this.currentHotspot.question != null){
            this.question = true
          }
          else if(this.currentHotspot.settings != null){
            this.settings = true
          }
          else if(this.currentHotspot.feedback != null){
            this.feedback = true
          }

          //show modal
          $('#staticBackdrop').modal('show')
        }else{
          alert('The hotspot opened before.')
        }
      }
      else {
        this.faild()
      }
    },

    //check if the hotspot oppened before 
    didNotOpennedBefore(){
      var result = true;
      this.allOpennedHotspots.forEach( (value, index) => {
          if(value.hotspot_id == this.currentHotspot.hotspot_id){
            result = false;
          }
      });
      return result;
    },

    // function that show hotspot title on hover
    hoverOnHotspot(hotspotTitle){
      if (this.hotspots.body.some(el => el.label === hotspotTitle)) {
        this.hotspotTooltip = this.hotspots.body.filter(function (el) {
          return el.label.replace(/\s/g, "-") == hotspotTitle.replace(/\s/g, "-");
        });
        this.tooltip = this.hotspotTooltip[0].hotspot_title
      }
      else{
        this.hotspotTooltip = this.hotspots.all_hotspots.filter(function (el) {
          return el.label.replace(/\s/g, "-") == hotspotTitle.replace(/\s/g, "-");
        });
        this.tooltip = this.hotspotTooltip[0].title
      }

    }

  },
  mounted() {
    this.getHotspots();

    //disable navigation buttons
    this.$store.dispatch('prevNavigationStatus', false)
    this.$store.dispatch('nextNavigationStatus', false)

    embedpano({swf: `${this.hotspots.model.swf}`, xml: `${this.hotspots.model.xml}`, target:'pano', html5:'auto', mobilescale:1.0, passQueryParameters:true})
      let _this = this;
      $(document).on("clickedHotspot", function(event , hotspotLabel){
        _this.loadpopup(hotspotLabel)
      });
      $(document).on("hoverHotspot", function(event , hotspotTitle){
        _this.hoverOnHotspot(hotspotTitle)
      });
      

    function addScript() {
      var s = document.createElement("script");
      s.setAttribute("type", "text/javascript");
      s.setAttribute("id", "scriptPano");
      s.innerHTML = ` 
        function loadHotspot(hotspotLabel){
          $(document).trigger("clickedHotspot", [hotspotLabel]);
        };
        function hotspotOnOver(hotspotTitle){
          $(document).trigger("hoverHotspot", [hotspotTitle]);
          document.addEventListener( 'mousemove', currentCursorPosition );
        };
        function hotspotOnOut(){
          document.removeEventListener( 'mousemove', currentCursorPosition );
          var tooltipSpan = document.getElementById('tooltip-span');
          tooltipSpan.style.display = 'none';
        };
        function currentCursorPosition(event){
          var tooltipSpan = document.getElementById('tooltip-span');
          var tooltipWidth = tooltipSpan.offsetWidth
          tooltipSpan.style.top = (event.clientY - 40) + 'px';
          tooltipSpan.style.left = (event.clientX - (tooltipWidth/2)) + 'px';
          tooltipSpan.style.display = 'block';
        };
      `;
      document.getElementById("pano").appendChild(s);
    }
    addScript();

    this.$store.dispatch( 'setInstructions', this.$store.getters['hotspots'].instructions )

    this.hotspot = null
    this.opennedHotspot = {}
    this.allOpennedHotspot = []
    this.nextHotspot = null
    this.index = 0

    this.$store.dispatch( 'setStartTime')
  },
  beforeRouteEnter (to, from, next) {
    if(store.getters["hotspots"].body.length <= 0){
      store.state.step = store.state.step + 1
      next({ name: `step${store.state.step}`, query: {'exp': store.state.case_key} });
    }
    else{
      next()
    }
  },
  beforeRouteLeave (to, from, next) {
    //unbind the event listener of the hotspots
    $(document).unbind( "clickedHotspot" );
    $(document).unbind( "hoverHotspot" );

    //send the opened hotspot to the backend if exist
    if(this.allOpennedHotspots.length > 0){
        this.$store.dispatch('hotspot', this.allOpennedHotspots).then(_=>{
        this.hotspot = null
        this.opennedHotspot = {}
        this.allOpennedHotspot = []
        this.nextHotspot = null
        this.index = 0
        this.$store.dispatch( 'setEndTime' , this.$options.name)
        next()
      })
    }
    else{
      this.$store.dispatch( 'setEndTime' , this.$options.name)
      next()
    }
    
  },
};
</script>

<style scoped>
#comp {
  height: 100%;
}
#pano {
  width: 100%;
  height: 100%;
}
.modal-body{
  padding: 0px !important;
}
.modal{
  z-index: 9999999999999999999999 !important;
}
h3{
  margin-top: 0 !important;

}
.modal-title{
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  background-color: #48d8d8;
  color: #fff;
}
.submit{
  background-color: #48D8D8;
  font-size: large;
  border-color: #48D8D8;
  border-bottom: 2px solid #002148;
  border-radius: 13px;
  padding: 5px 30px;
}
.round {
  border-radius: 5px !important;
}
#tooltip-span{
  position: absolute;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  text-shadow: 2px 2px rgb(51, 51, 51);
}

label{
  display: unset !important;
}
</style>